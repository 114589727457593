// use .js-lazyload instead of .lazyload
window.lazySizesConfig.lazyClass = 'js-lazyload';
window.lazySizesConfig.loadedClass = 'is-loaded';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes';
import $ from 'jquery';

// add lazyload-wrapper-target option to add is-loaded class to a parent element
$(document).on('lazyloaded', (e) => {
    var lazyloadedElement = $(e.target);
    var wrapperTargetSelector = lazyloadedElement.data('lazyload-wrapper-target') ? $(e.target).data('lazyload-wrapper-target') : '.lazyload-wrapper';
    var wrapperTargetElement = lazyloadedElement.closest(wrapperTargetSelector);

    if (wrapperTargetElement) {
        wrapperTargetElement.addClass(window.lazySizesConfig.loadedClass);
    }
});
